<template>
  <div>
    <div class="head">
      <div class="h_info">
        <div class="left_info">
          <div v-if="user" style="display:flex;align-items: center;cursor: pointer;">
            <div class="header" @click="goMem">
              <img class="avator"
                :src="user.MemberPersonalPic?user.MemberPersonalPic:require('../assets/img_head24.png')" alt="">
              <img v-if="user.AssociatorState=='已开通'" class="vip" src="../assets/icon_vip24.png" alt="">
            </div>
            <div @click="goMem" class="login" style="margin-right:8px;cursor: pointer;">{{user.MemberName}}</div>
            <div class="login" @click="loginOut">退出</div>
          </div>
          <div v-if="!user" class="login" @click="loginIn">登录</div>
        </div>
        <div class="right_info" @mouseover="showTime=true" @mouseout="showTime=false">
          <img src="../assets/icon_phine.png" alt="">
          <span>400-859-1580</span>
          <div class="work" v-show="showTime">8:30 - 17:30</div>
        </div>
      </div>
    </div>
    <div class="nav">
      <img class="logo" @click="$router.push({path:'/index'})" src="../assets/icon_logo71.png" alt="">
      <div class="nav_list">
        <div class="first" :class="{'selected':headSelectIndex==1}" @click="getPage('index','','首页')">首页</div>
        <div class="consult" @mouseover="showDefault=true" @mouseout="showDefault=false"
          :class="{'selected':headSelectIndex==2}">
          <img class="sult" v-show="!showDefault && headSelectIndex!=2" src="../assets/text_consult16.png" alt="">
          <img class="sult1" v-show="showDefault ||headSelectIndex==2" src="../assets/text_consult_selected.png" alt="">
          <img class="hot" src="../assets/icon_hot18.png" alt="">
          <div class="level_box" v-show="showDefault &&headSelectIndex!=2">
            <div class="level">
              <div style="font-weight:400" @click.prevent="getPage('ask',1,'我要咨询')">图文咨询</div>
              <div style="font-weight:400" @click.prevent="getPage('ask',2,'我要咨询')">电话咨询</div>
            </div>
          </div>
        </div>
        <div class="hovers" :class="{'selected':headSelectIndex==3}" @click="getPage('recommend','','律师推荐')">律师推荐</div>
        <div class="hovers" :class="{'selected':headSelectIndex==4}" @click="getPage('contract','','合同模板')">合同模板</div>
      </div>
    </div>
    <div v-if="outLogin" class="loginout">
      <div class="loginbox">
        <div class="close" @click="outLogin=false"><img src="../assets/icon_close.png" alt=""></div>
        <div class="issure">是否确定退出当前账号</div>
        <div class="btns">
          <div class="cancel" @click="outLogin=false">取消</div>
          <div class="enter" @click="sureOut">确定</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import WebIM from '../utils/WebIM';
  export default {
    props: {
      headSelectIndex: {
        default: 1
      }
    },
    data() {
      return {
        showTime: false,
        showDefault: false,
        outLogin: false,
        user: null
      }
    },
    mounted() {
      this.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
      this.isMember().then(res => {
        this.user = localStorage.getItem('user') ? JSON.parse(localStorage.getItem('user')) : null
      })
    },
    methods: {
      getPage(path, res, title) {
        _hmt.push(['_trackEvent', 'click', title, 'pc']);
        if (res) {
          if (this.user) {
            this.$router.push({
              path: '/ask',
              query: {
                id: res
              }
            })
          } else {
            this.$router.push('/login?to=ask&id=' + res)
          }

        } else {
          this.$router.push(`/${path}`)
        }
      },
      goMem() {
        this.$router.push('/center?id=' + 1)
      },
      loginOut() {
        this.outLogin = true
      },
      loginIn() {
        this.$router.push('/login')
      },
      sureOut() {
        this.user = null
        this.outLogin = false
        WebIM.conn.close()
        localStorage.removeItem('user')
        this.$router.push('/index')
      }
    }
  }

</script>

<style scoped lang="scss">
  .head {
    box-sizing: border-box;
    height: 40px;
    line-height: 40px;

  }

  .h_info {
    display: flex;
    justify-content: space-between;
    margin: auto;
    width: 940px;
  }

  .header {
    position: relative;
    margin-right: 8px;
    width: 24px;
    height: 24px;

    .avator {
      border-radius: 50%;
      width: 100%;
      height: 100%;
    }

    .vip {
      position: absolute;
      bottom: -3px;
      right: -4px;
      width: 12px;
      height: 11px;
    }
  }

  .login {
    margin-top: 3px;
    font-size: 16px;
    color: #FFFFFF;
    cursor: default;
  }

  .left_info {
    display: flex;
    align-items: center;
  }

  .right_info {
    position: relative;
    display: flex;
    align-items: center;
    color: #fff;
    font-size: 16px;
    font-weight: bold;

    img {
      margin-right: 8px;
      width: 16px;
      height: 16px;
    }

    span {
      margin-top: 2px;
    }

    .work {
      position: absolute;
      top: 40px;
      left: 19px;
      width: 110px;
      height: 30px;
      line-height: 30px;
      font-size: 14px;
      color: #666666;
      text-align: center;
      background: #FFFFFF;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2600);
      border-radius: 10px;
    }
  }

  .nav {
    margin: 0 auto 34px;
    padding-top: 20px;
    width: 940px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .logo {
      width: 140px;
      height: 71px;
    }
  }

  .consult {
    position: relative;
    width: 90px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;

    .sult {
      width: 71px;
      height: 16px;
    }

    .sult1 {
      width: 71px;
      height: 16px;
    }

    .hot {
      position: absolute;
      width: 16px;
      height: 18px;
      top: -7px;
      right: -8px;
    }

    .level_box {
      position: absolute;
      padding: 14px 0;
      left: 1px;
      bottom: -101px;
      z-index: 3;
    }

    .level {
      padding-top: 10px;
      width: 90px;
      height: 80px;
      background: #FFFFFF;
      box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.2600);
      border-radius: 10px;
      box-sizing: border-box;

      div {
        margin: 0 auto 10px;
        width: 80px;
        height: 26px;
        line-height: 26px;
        text-align: center;
        border-radius: 6px;
        color: #AAAAAA;
        font-size: 1px;
      }

      div:hover {
        background: #3CD3CC;
        color: #fff;
      }
    }
  }

  .consult:hover {
    background: #fff;
  }

  .nav_list {
    display: flex;
    align-items: center;
    height: 24px;
    font-size: 18px;
    color: #fff;
  }

  .nav_list>div {
    margin: 0 19px 0 20px;
    cursor: default;
  }

  .first {
    width: 60px;
    height: 24px;
    line-height: 24px;
    text-align: center;
    color: #fff;
    border-radius: 12px;
  }

  .first:hover {
    color: #21D0C8;
    background: #fff;
  }

  .selected {
    color: #21D0C8;
    background: #fff;
    border-radius: 12px;
    font-weight: bold;
  }

  .hovers {
    width: 96px;
    height: 24px;
    line-height: 24px;
    text-align: center;
  }

  .hovers:hover {
    font-weight: bold;
    color: #21D0C8;
    background: #fff;
    border-radius: 12px;
  }

  .loginout {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    bottom: 0;
    background: rgba($color: #000000, $alpha: 0.7);
    z-index: 99999;

    .loginbox {
      position: relative;
      width: 390px;
      height: 260px;
      background: #FFFFFF;
      border-radius: 10px;
      text-align: center;

      .close {
        position: absolute;
        right: 0;
        top: 0;
        width: 30px;
        height: 30px;

        img {
          width: 100%;
          height: 100%;
        }
      }

      .issure {
        padding-top: 90px;
        font-size: 20px;
        color: #333333;
        text-align: center;
      }

      .btns {
        display: flex;
        justify-content: center;
        margin-top: 58px;
        font-size: 18px;
        cursor: default;

        .cancel {
          margin-right: 20px;
          width: 120px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background: #E9EEF4;
          border-radius: 20px;
          color: #666666;
        }

        .enter {
          width: 120px;
          height: 40px;
          text-align: center;
          line-height: 40px;
          background: #00C8B4;
          border-radius: 20px;
          color: #FFFFFF;
        }
      }
    }
  }

</style>
